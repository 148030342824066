import { ImageRoutes } from 'core/constants/image-routes';
import { EPromotionDetailType } from 'core/model/enums/promotion.enum';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import {
  IPaginatedProducts,
  IProduct,
  IProductDetail,
  IUnitMeasureDetail,
} from 'core/model/interfaces/product.interface';
import { IGetListProductsDTO, INavigationCatalogDTO, IProductDetailDTO, IProductDTO } from './catalogs.dto';
import { getActiveUnitMeasures, getDefaultUnitMeasure, getUnitMeasure, getUnitMeasureDetail } from './catalogs.utils';

export const productDTOToIProduct = (product: IProductDTO): IProduct => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  const activeUnitMeasures = getActiveUnitMeasures(unitMeasure, product.sku);
  const defaultUnitMeasure = getDefaultUnitMeasure(activeUnitMeasures);
  const selectedUnitMeasure = getUnitMeasure(defaultUnitMeasure, activeUnitMeasures);

  return {
    brand,
    currency,
    name,
    eanCode,
    sku,
    stock,
    sourceId,
    taxRate,
    description,
    selectedUnitMeasure,
    price: selectedUnitMeasure.price,
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    commercialName: commercialName || name,
    categoryId: Number(externalCategory.id),
    subcategoryId: Number(externalFamily.id),
    presentation: selectedUnitMeasure.presentation,
    originalPrice: selectedUnitMeasure.originalPrice,
    image: image || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT,
    hasDiscount: selectedUnitMeasure.hasPromotion ?? false,
    minimumSaleQuantity: selectedUnitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: selectedUnitMeasure.maximumSaleQuantity,
  };
};

export const navigationCatalogToCatalogueProducts = (
  catalog: INavigationCatalogDTO,
  treeItemType: 'subcategory' | 'category',
): ICatalogueProducts => ({
  [treeItemType]: {
    guid: catalog.treeItemId.toString(),
    name: catalog.description,
  },
  products: catalog.catalog.products.map(productDTOToIProduct),
  pagination: catalog.catalog.pagination,
});

export const listProductsToPaginatedProducts = ({ pagination, products }: IGetListProductsDTO): IPaginatedProducts => ({
  products: products.map(productDTOToIProduct),
  pagination,
});

export const catalogDetailResponseToProduct = (product: IProductDetailDTO): IProductDetail => {
  const {
    brand,
    name,
    currency,
    description,
    image,
    sku,
    sourceId,
    stock,
    taxRate,
    unitMeasure,
    eanCode,
    externalCategory,
    externalFamily,
    externalProvider,
    commercialName,
    externalDistributor,
  } = product;

  const activeUnitMeasures = getActiveUnitMeasures(unitMeasure, product.sku);
  const defaultUnitMeasure = getDefaultUnitMeasure(activeUnitMeasures);
  const selectedUnitMeasure = getUnitMeasureDetail(defaultUnitMeasure);

  const unitMeasures: Array<IUnitMeasureDetail> = activeUnitMeasures.map(unitMeasure => ({
    ...getUnitMeasureDetail(unitMeasure),
  }));

  const hasDiscountInAnyUnitMeasure =
    unitMeasures
      .flatMap(uM => uM.promotions.simple)
      .some(promotion => promotion.type === EPromotionDetailType.DISCOUNT) ||
    unitMeasures
      .flatMap(uM => uM.promotions.tiered)
      .some(promotion => promotion.type === EPromotionDetailType.DISCOUNT) ||
    unitMeasures.flatMap(uM => uM.promotions.combo).some(promotion => promotion.type === EPromotionDetailType.DISCOUNT);

  const productDetail: IProductDetail = {
    brand,
    currency,
    name,
    eanCode,
    sku,
    stock,
    sourceId,
    taxRate,
    description,
    selectedUnitMeasure,
    price: selectedUnitMeasure.price,
    categoryName: externalCategory.name,
    subcategoryName: externalFamily.name,
    providerDisplayName: externalProvider.name,
    distributorName: externalDistributor.name,
    commercialName: commercialName || name,
    categoryId: Number(externalCategory.id),
    subcategoryId: Number(externalFamily.id),
    presentation: selectedUnitMeasure.presentation,
    originalPrice: selectedUnitMeasure.originalPrice,
    image: image || ImageRoutes.CATALOGUE_DEFAULT_PRODUCT,
    hasDiscount: hasDiscountInAnyUnitMeasure ?? false,
    minimumSaleQuantity: selectedUnitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: selectedUnitMeasure.maximumSaleQuantity,
    unitMeasures,
    distributor: {
      id: product.externalDistributor.id,
      name: product.externalDistributor.name,
      document: product.externalDistributor.document,
    },
  };
  return productDetail;
};
