import { EPromotionDetailType, EPromotionType } from 'core/model/enums/promotion.enum';
import { IUnitMeasure, IUnitMeasureDetail } from 'core/model/interfaces/product.interface';
import { IDetailedPromotion, ITierPromotionDetail } from 'core/model/interfaces/promotion.interface';
import { IUnitMeasureDetailDTO, IUnitMeasureDTO } from './catalogs.dto';
import { IDetailedPromotionDto, ITieredPromotionDto } from './catalogs-promotions.dto';

export const getPromotionProperties = (selectedUM: IUnitMeasureDTO, unitMeasures: Array<IUnitMeasureDTO>) => {
  let hasDiscount = false;
  const tieredDiscountFound: { active: boolean; maxDiscount?: number } = { active: false, maxDiscount: undefined };
  let hasSimpleBonus = false;
  let hasTieredBonus = false;
  let hasComboBonus = false;
  let hasComboDiscount = false;

  const hasPromotionInAllUnitMeasure = unitMeasures
    .flatMap(uM => uM.promotions)
    .some(promotion =>
      [
        EPromotionType.SIMPLE_DISCOUNT,
        EPromotionType.TIERED_DISCOUNT,
        EPromotionType.SIMPLE_BONUS,
        EPromotionType.TIERED_BONUS,
      ].includes(promotion.type),
    );

  selectedUM.promotions.forEach(promotion => {
    switch (promotion.type) {
      case EPromotionType.SIMPLE_DISCOUNT:
        hasDiscount = true;
        break;
      case EPromotionType.TIERED_DISCOUNT:
        tieredDiscountFound.active = true;
        tieredDiscountFound.maxDiscount = promotion.maxDiscount;
        break;
      case EPromotionType.SIMPLE_BONUS:
        hasSimpleBonus = true;
        break;
      case EPromotionType.TIERED_BONUS:
        hasTieredBonus = true;
        break;
      case EPromotionType.COMBO_BONUS:
        hasComboBonus = true;
        break;
      case EPromotionType.COMBO_DISCOUNT:
        hasComboDiscount = true;
        break;
      default:
        break;
    }
  });

  const hasPromotionTag = hasPromotionInAllUnitMeasure;
  const hasComboTag = hasComboBonus || hasComboDiscount;
  const maxDiscount = tieredDiscountFound?.maxDiscount;
  const hasGift = hasSimpleBonus || hasTieredBonus;

  return {
    hasPromotionTag,
    hasComboTag,
    maxDiscount,
    hasGift,
    hasDiscount,
  };
};

export const getDetailPromotionProperties = (selectedUM: IUnitMeasureDetailDTO) => {
  const { simple = [], tiered = [], combo = [] } = selectedUM.promotions;
  const hasComboBonus = combo.some(promo => promo.type === EPromotionDetailType.BONUS);
  const hasComboDiscount = combo.some(promo => promo.type === EPromotionDetailType.DISCOUNT);
  const hasComboTag = hasComboBonus || hasComboDiscount;
  const hasAnyPromotion = simple.length > 0 || tiered.length > 0 || combo.length > 0;
  const hasDiscount = simple.length > 0;
  const maxDiscount = getMaxDiscount(tiered);
  const hasGift = [...simple, ...tiered].some(promo => promo.type === EPromotionDetailType.BONUS);

  return {
    hasAnyPromotion,
    hasComboTag,
    maxDiscount,
    hasGift,
    hasDiscount,
  };
};

const getMaxDiscount = (tieredPromos: Array<ITieredPromotionDto>): number => {
  let maxDiscount = 0;
  tieredPromos.forEach(promo => {
    const lastTierInCurrentPromo = promo.limits[promo.limits.length - 1];
    if (lastTierInCurrentPromo.discount && lastTierInCurrentPromo.discount > maxDiscount) {
      maxDiscount = lastTierInCurrentPromo.discount;
    }
  });
  return maxDiscount;
};

export const buildDetailedPromotions = (data: IDetailedPromotionDto): IDetailedPromotion => {
  const promotions: IDetailedPromotion = {
    simple: [],
    tiered: [],
    combo: [],
  };
  const { simple = [], tiered = [], combo = [] } = data;
  promotions.simple = simple.map(simplePromotion => ({
    type: simplePromotion.type,
    description: simplePromotion.description,
    discount: simplePromotion.discount,
    bonus: simplePromotion.bonus,
    id: simplePromotion.id,
  }));

  promotions.tiered = tiered.map(tieredPromotion => {
    const tiers: Array<ITierPromotionDetail> = [];
    tieredPromotion.limits.forEach(limit => {
      const { discount, lower, upper, bonus } = limit;
      const hasDiscount = discount !== undefined;
      const descriptionText = hasDiscount ? ` obtén ${(discount * 1000) / 10}% de dscto` : `llévate ${bonus}`;

      tiers.push({
        maxQuantity: upper,
        minQuantity: lower,
        descriptionText,
      });
    });

    return {
      description: tieredPromotion.description,
      id: tieredPromotion.id,
      limitType: tieredPromotion.limitType,
      tiers,
      type: tieredPromotion.type,
    };
  });

  promotions.combo = combo;

  return promotions;
};

export const getUnitMeasureDetail = (unitMeasure: IUnitMeasureDetailDTO): IUnitMeasureDetail => {
  const { hasAnyPromotion, hasComboTag, maxDiscount, hasGift, hasDiscount } = getDetailPromotionProperties(unitMeasure);

  return {
    code: unitMeasure.code,
    default: unitMeasure.default,
    name: unitMeasure.description,
    presentation: unitMeasure.presentation,
    conversionFactor: unitMeasure.conversionFactor,
    price: unitMeasure.price,
    originalPrice: unitMeasure.originalPrice,
    minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
    hasPromotion: hasAnyPromotion,
    hasCombo: hasComboTag,
    maxDiscount,
    hasGift,
    hasDiscount,
    promotions: buildDetailedPromotions(unitMeasure.promotions),
  };
};
export const getUnitMeasure = <T extends IUnitMeasureDTO>(unitMeasure: T, unitMeasures: Array<T>): IUnitMeasure => {
  const { hasPromotionTag, hasComboTag, maxDiscount, hasGift, hasDiscount } = getPromotionProperties(
    unitMeasure,
    unitMeasures,
  );

  return {
    code: unitMeasure.code,
    default: unitMeasure.default,
    name: unitMeasure.description,
    presentation: unitMeasure.presentation,
    conversionFactor: unitMeasure.conversionFactor,
    price: unitMeasure.price,
    originalPrice: unitMeasure.originalPrice,
    minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
    hasPromotion: hasPromotionTag,
    hasCombo: hasComboTag,
    maxDiscount,
    hasGift,
    hasDiscount,
    promotions: unitMeasure.promotions,
  };
};

export const getDefaultUnitMeasure = <T extends { default: boolean }>(unitMeasures: Array<T>) =>
  unitMeasures.find(um => um.default === true) || unitMeasures[0];

export const getActiveUnitMeasures = <T extends IUnitMeasureDTO | IUnitMeasureDetailDTO>(
  unitMeasures: Array<T>,
  sku: string,
) => {
  const activeUnitMeasures = unitMeasures.filter(um => um.isActive === true);
  if (activeUnitMeasures.length < 1) throw new Error(`There are no active unit measures for sku: ${sku}`);
  return activeUnitMeasures;
};
